@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "lili";
  src: url("./assets/font/LilitaOne-Regular.ttf");
}

body {
  font-family: "lili";
  background-color: rgb(6, 31, 49);
}


.backgrop-bg-filter {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(4px);
}

.home-bg {
  background-image: url("/public/media/parrot_meme/banner.jpg");
}
.gradient-style-1 {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: bganimate 5s ease infinite;
}

@keyframes bganimate {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

